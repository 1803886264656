import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: resolve => require(['../views/layout/layout.vue'], resolve ),
    children: [
      { path: '/', name: 'home', component: resolve => require(['../views/home/home.vue'], resolve )},
      { path: '/nkopen', name: 'mything', component: resolve => require(['../views/details/nkopen/nkopen.vue'],resolve) },
      { path: '/details', name: 'nkdetails', component: resolve => require(['../views/details/nkdetails/nkdetails.vue'],resolve) },
      { path: '/chooseList', name: 'chooseList', component: resolve => require(['../views/details/chooseList/chooseList.vue'],resolve)},
      { path: '/fromlist', name: 'fromlist', component: resolve => require(['@/components/contentlist/fromlist.vue'],resolve)},
      { path: '/contentItems', name: 'contentItems', component: resolve => require(['@/components/contentlist/contentItems.vue'],resolve)},
      { path: '/moreBtn', name: 'moreBtn', component: resolve => require(['@/views/details/more/moreBtn.vue'],resolve) },
      { path: '/moreList', name: 'moreList', component: resolve => require(['@/views/details/more/moreList.vue'],resolve) },
      { path: '/personal', name: 'personal', component: resolve => require(['@/views/scenarioService/personal.vue'],resolve) },
      { path: '/personDetail', name: 'personDetail', component: resolve => require(['@/views/scenarioService/personDetail/personDetail.vue'],resolve) },
      { path: '/oneThing', name: 'oneThing', component: resolve => require(['@/views/scenarioService/oneThing/oneThing.vue'],resolve) },
      { path: '/scContent', name: 'scContent', component: resolve => require(['@/views/scenarioService/scContent/scContent.vue'],resolve) },
      { path: '/scList', name: 'scList', component: resolve => require(['@/views/scenarioService/scList/scList.vue'],resolve) },
      { path: '/err', name: 'err', component: resolve => require(['@/views/err/err.vue'],resolve) },
    ]
  },
]


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  if(to.path == '/'){
    store.commit('setPathUrl', 0)
  }
  next()
})


export default router
