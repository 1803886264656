<template>
  <div :class="isShow?'page':'page1'">
    <!-- :color="" -->
    <van-icon name="arrow-left" class="icon_left" @click="jump('left')" size="20"/>
    <van-icon name="arrow" @click="jump('right')" :color="historyNum1?'#a8a8a8':'#2e3e50'" size="20"/>
  </div>
</template>

<script>
export default {
  name:"page",
  data(){
    return{
      historyNum:true,
      historyNum1:true,
      isShow: true,
      i:0,
    }
  },
  computed:{
    num(){
      return this.$store.state.pathUrl
    }
  },
  watch:{
    num:{
      handler(newval){
        if(newval<1){
          this.historyNum1 = true
        }else{
          this.historyNum1 = false
        }
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    jump(name){
      if(name == 'left'){
          let sum = this.num
          sum+=1
          this.$store.commit('setPathUrl', sum)
          this.$router.go(-1)
      }else{
        // console.log(this.$store.state.pathUrl)
          let sum = this.num
          sum-=1
          this.$store.commit('setPathUrl', sum)
          this.$router.go(1)
      }
    },
    isScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if(scroll<=0){
          this.isShow = true
      }else{
          this.isShow = false
      }
    }
  },
  created(){
    
  },
  mounted () {
    window.addEventListener('scroll', this.isScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.isScroll)
  }
}
</script>

<style lang="less" scoped>
.page{
    height:1.378rem;
    line-height: 1.378rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    text-align: center;
    transition: height 1s ease;
    box-shadow: 0px 0rem 0.0444rem #cacdd1;
    left: 0;
    .icon_left{
      margin-right: 1.8889rem;
    }
}
.page1{
    height:0;
    line-height: 1.778rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    text-align: center;
    transition: height 1s;
    left: 0;
    .icon_left{
      margin-right: 1.8889rem;
    }
}
</style>