<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name:"app",
  created(){
    this.init()
  },
  mounted(){
    // console.log(window.getComputedStyle(document.querySelector('html'),null).getPropertyValue('font-size'))
    document.title = '津心办·南开旗舰店'
  },
  methods:{
    init(){
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsApp = sUserAgent.indexOf('jxb_hanweb') > -1;
      this.$store.commit('setJxbApp',bIsApp)
    },
  },

  
}
</script>
<style lang="less">
#app {
  font-family: "Avenir","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
*{
  margin: 0;
  padding: 0;
}
</style>
